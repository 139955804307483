export default () => ({
    menuIsOpen: false,
    trapFocus: false,
    isMobile: (window.innerWidth < 640) ? true : false,
    headerColorScheme: 'dark',
    atTopScreen: true,

    toggleMenu() {
        if (this.menuIsOpen) {
            this.trapFocus = false
            this.menuIsOpen = false
            return
        }

        this.menuIsOpen = true
        setTimeout(() => {
            this.trapFocus = true
        }, 2000)

    },

    closeMenu() {
        this.trapFocus = false
        this.menuIsOpen = false
    }
})