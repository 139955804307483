import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import PageHeader from './components/PageHeader.js'
import Body from './components/Body.js'

import lazySizes from 'lazysizes'
import 'lazysizes/plugins/respimg/ls.respimg'

import { detect } from 'detect-browser'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Swup from 'swup';
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import SwupDebugPlugin from '@swup/debug-plugin'
import SwupA11yPlugin from '@swup/a11y-plugin'

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import Plausible from 'plausible-tracker'

const browser = detect()
document.body.classList.add(browser.name)

const isMobile = browser.os === 'iOS' || browser.os === 'Android OS'

const plausible = Plausible({
  domain: 'celinemosawi.de',
})

plausible.trackPageview()

let swiper = null

if(document.getElementById('swiper-hero')) {
  swiper = new Swiper('#swiper-hero', {
    loop: true,
    speed: 300,
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    allowTouchMove: false,
    preloadImages: true,
  });
  
  setColorScheme(swiper)

  swiper.on('slideChange', function () {
    if(document.getElementById('swiper-hero')) {
        setColorScheme(swiper, swiper.activeIndex)
    }
  });
}

const swup = new Swup({
  containers: ['#swup-main', '#swup-page-header', '#swup-page-footer'],
  plugins: [new SwupBodyClassPlugin(),
    new SwupDebugPlugin(),
    new SwupScrollPlugin({
      doScrollingRightAway: true,
      animateScroll: false,
    }),
    // new SwupA11yPlugin({
    //   announcementTemplate: 'Navigiert zu: {title}',
    //   urlTemplate: 'Neue Seite mit der URL {url}'
    // })
  ]
});

// Alpine
Alpine.data('PageHeader', PageHeader)
Alpine.data('Body', Body)

window.Alpine = Alpine
Alpine.plugin(focus)
Alpine.start()

gsap.registerPlugin(ScrollTrigger);

const introTrigger = ScrollTrigger.create({
  start: 'top -20',
  end: 99999,
  toggleClass: {className: 'opacity-0', targets: '.intro-headline'}
});


// if(browser.name !== 'safari') {
//   const logo = gsap.to(".logo-circle", {
//     rotation: 360, 
//     duration: 15, 
//     ease: "none", 
//     repeat: -1 
//   });

//   let tl = gsap.timeline();

//   const logoTrigger = ScrollTrigger.create({
//     trigger: '.logo-circle-wrapper',
//     start: 0,
//     end: 99999,
//     onUpdate({ getVelocity }) {
//       const velocity = getVelocity();
          
//       let timeScale = 1;
//       let endTimeScale = 1;
      
//       if (velocity >= 1) {
//         timeScale = Math.min(parseInt(velocity), 300)/15;
//       }
//       else {
//         timeScale = Math.max(parseInt(velocity), 200)/-15;
//       }
          
//       tl.clear()
//         .to(logo, {
//           duration: 0.2,
//           timeScale: timeScale,
//           ease: "power2.out"
//         })
//         .to(logo, {
//           duration: 0.2,
//           timeScale: endTimeScale,
//           ease: "power2.out"
//         }, "0");
//     }
//   })
// }


swup.on('clickLink', (ev) => {

  changeHeaderColorScheme('dark')

  plausible.trackPageview()

  swiper = null

  setTimeout(() => {    
    if(document.getElementById('swiper-hero')) {

      swiper = new Swiper('#swiper-hero', {
        loop: true,
        speed: 300,
        effect: 'fade',
        autoplay: {
          delay: 5000,
        },
        allowTouchMove: false,
        preloadImages: true,
      });

      setColorScheme(swiper)

      swiper.on('slideChange', function () {
        if(document.getElementById('swiper-hero')) {
            setColorScheme(swiper, swiper.activeIndex)
        }
      });
    }
  }, 500)
})


function changeHeaderColorScheme (c) {
  const event = new CustomEvent('change-contrast', {
    detail: {
      headerColorScheme: c
    }
  })

  window.dispatchEvent(event)
}

function setColorScheme (swiper, index = 1) {
  const currentColorScheme = swiper.slides[index].dataset.colorScheme
  changeHeaderColorScheme(currentColorScheme)
}


const allElements = document.getElementsByTagName("*");

function initCursor(event) {
  document.body.style.cursor = "none";

  for (let i = 0, len = allElements.length; i < len; i++) {
      const element = allElements[i];
      element.style.cursor = "none";
  }

  const cursor = document.querySelector(".custom-cursor");
  cursor.classList.remove("custom-cursor--link");

  const links = document.querySelectorAll("a:not(.logo), button, .cursor-inverted");
  let initCursor = false;


  for (let i = 0; i < links.length; i++) {
    const selfLink = links[i];

    selfLink.addEventListener("mouseover", function() {
      if(selfLink.classList.contains("cursor-inverted")) {
        cursor.classList.add("custom-cursor--inverted");
      }

      if(selfLink.nodeName === 'A' || selfLink.nodeName === 'BUTTON') {
        cursor.classList.add("custom-cursor--link");
        if(selfLink.classList.contains("button-coffee")) {
          cursor.classList.add("custom-cursor--coffee");
        }
      }
    });

    selfLink.addEventListener("mouseout", function() {
      cursor.classList.remove("custom-cursor--link");
      cursor.classList.remove("custom-cursor--coffee");
      cursor.classList.remove("custom-cursor--inverted");
    });
  }


  window.onmousemove = function(e) {
    let mouseX = e.clientX;
    let mouseY = e.clientY;

    if (!initCursor) {
      gsap.to(".custom-cursor", {
        opacity: 1
      });
      initCursor = true;
    }

    gsap.to(".custom-cursor", {
      translateX: mouseX + "px",
      translateY: mouseY + "px",
      duration: 0.1
    });
  };

  window.onmouseout = function(e) {
    gsap.to(".custom-cursor", {
      opacity: 0
    });
    initCursor = false;
  };
}

if(!isMobile) {
  initCursor()
}


swup.on('contentReplaced', (event) => {
  if(!isMobile) {
    initCursor(event)
  }
});
